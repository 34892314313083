import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

export const MUiDatePicker = ({
  name,
  fieldId,
  minDate,
  maxDate,
  selected,
  labelName = "pick the date",
  onChange,
  errorMessage,
  valid = true,
  readOnly,
  size = "small",
  autoComplete = "on",
  variant = "outlined",
  fullWidth = true,
  sx,
}: IDate) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        disabled={readOnly}
        minDate={minDate}
        maxDate={maxDate}
        label={labelName}
        value={selected ?? ""}
        onChange={onChange}
        renderInput={(params) => (
          <TextField
            autoComplete={autoComplete}
            size={size}
            variant={variant}
            fullWidth={fullWidth}
            name={name}
            {...params}
            error={!valid}
            helperText={!valid && errorMessage}
            id={fieldId}
            sx={sx}
          />
        )}
      />
    </LocalizationProvider>
  );
};

export interface IDate {
  name: string;
  fieldId: string;
  onChange: any;
  selectsStart?: any;
  minDate?: any;
  selected?: any;
  value?: any;
  formatDate?: any;
  maxDate?: any;
  errorMessage?: string;
  labelName?: string;
  valid?: any;
  readOnly?: boolean;
  dateFormat?: any;
  monthsShown?: any;
  startDate?: any;
  endDate?: any;
  fullWidth?: boolean;
  size?: "small" | "medium";
  autoComplete?: "off" | "on";
  variant?: "outlined" | "filled" | "standard";
  sx?: any;
}
