import * as React from "react";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { TransitionProps } from "@mui/material/transitions";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const Modal = ({
  title,
  content,
  footer,
  open,
  setOpen,
  size = "lg",
  escape = true,
  modalId,
  fullScreen = false,
  scroll = "paper",
  closeIcon = false,
}: IModal) => {
  return (
    <Dialog
      onClose={setOpen}
      open={open}
      id={modalId}
      fullWidth={true}
      disableEscapeKeyDown={escape}
      fullScreen={fullScreen}
      maxWidth={size}
      scroll={scroll}
      TransitionComponent={Transition}
    >
      {title && <DialogTitle>{title}</DialogTitle>}
      {closeIcon && (
        <IconButton
          aria-label="close"
          onClick={setOpen}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            zIndex: 90,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      )}

      <DialogContent>{content}</DialogContent>
      {footer && <DialogActions>{footer}</DialogActions>}
    </Dialog>
  );
};

export interface IModal {
  title: any;
  content: any;
  modalId: string;
  footer?: any;
  size?: "xs" | "sm" | "md" | "lg" | "xl";
  open: boolean;
  setOpen: any;
  escape?: boolean;
  fullScreen?: boolean;
  scroll?: "body" | "paper";
  closeIcon?: boolean;
}
