import * as React from "react";
import Typography from "@mui/material/Typography";

export const TypographyComponent = ({
  id,
  label,
  variant = "h1",
  htmlTag,
  sx = { mb: 1, mt: 1 },
  color = "default",
}: ITypo) => {
  return (
    <Typography
      variant={variant}
      component={htmlTag}
      sx={sx}
      id={id}
      color={color}
    >
      {label}
    </Typography>
  );
};

export interface ITypo {
  id: string;
  label: string | React.ReactNode;
  variant?:
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "h5"
    | "h6"
    | "subtitle1"
    | "subtitle2"
    | "body1"
    | "body2"
    | "caption"
    | "button"
    | "overline"
    | "inherit";
  htmlTag?: any;
  sx?: any;
  color?:
    | "default"
    | "primary"
    | "secondary"
    | "error"
    | "info"
    | "success"
    | "warning";
}
