import React from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

export const InputTextField = ({
  name,
  fieldId,
  labelName,
  onChange,
  value = undefined,
  errorMessage,
  valid = true,
  onFocus,
  onKeyPress,
  onBlur,
  onKeyUp,
  onClick,
  disabled,
  type = "text",
  size = "small",
  autoComplete = "on",
  variant = "outlined",
  fullWidth = true,
  sx,
  adornmentMessage,
  adornmentPosition = "end",
  placeholder,
}: IText) => {
  return (
    <TextField
      fullWidth={fullWidth}
      id={fieldId}
      label={labelName}
      name={name}
      onKeyPress={onKeyPress}
      onKeyUp={onKeyUp}
      onClick={onClick}
      variant={variant}
      onChange={onChange}
      value={value}
      disabled={disabled}
      autoComplete={autoComplete}
      type={type}
      size={size}
      onFocus={onFocus}
      onBlur={onBlur}
      error={!valid}
      helperText={!valid && errorMessage}
      sx={sx}
      placeholder={placeholder}
      InputProps={
        adornmentMessage
          ? {
              endAdornment: (
                <InputAdornment position={adornmentPosition}>
                  {adornmentMessage}
                </InputAdornment>
              ),
            }
          : {}
      }
    />
  );
};

export interface IText {
  name: string;
  fieldId: string;
  onChange: any;
  value: any;
  errorMessage?: string;
  labelName?: string;
  valid?: any;
  onKeyPress?: any;
  onBlur?: any;
  onFocus?: any;
  onKeyUp?: any;
  onClick?: any;
  disabled?: boolean;
  placeholder?: string;
  type?: "text" | "number" | "password";
  size?: "small" | "medium";
  autoComplete?:
    | "off"
    | "on"
    | "name"
    | "honorific-prefix"
    | "given-name"
    | "family-name"
    | "additional-name"
    | "nickname"
    | "email"
    | "username"
    | "new-password"
    | "current-password"
    | "one-time-code"
    | "organization-title"
    | "organization"
    | "street-address"
    | "address-line1"
    | "address-line2"
    | "address-line3"
    | "address-level4"
    | "address-level3"
    | "country"
    | "country-name"
    | "postal-code"
    | "cc-name"
    | "cc-given-name"
    | "cc-exp"
    | "bday"
    | "sex"
    | "url"
    | "photo";
  variant?: "outlined" | "filled" | "standard";
  fullWidth?: boolean;
  sx?: any;
  adornmentMessage?: any;
  adornmentPosition?: "start" | "end";
}
