import React, { useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`ppc-tabpanel-${index}`}
      aria-labelledby={`ppc-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

export const TabsComponent = ({
  TabsTiles,
  TabsContent,
  vertical = false,
  underline = false,
  selectedIndex,
  setSelectedIndex,
  disabledIndex,
  disabledIndexes=[],
  centered = false,
  allowScrollButtonsMobile = true,
  scrollButtons = "auto",
  variant = "scrollable",
  visibleScrollbar = false,
  selectionFollowsFocus = true,
}: ITabs) => {
  const [value, setValue] = React.useState(selectedIndex);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setSelectedIndex(newValue);
  };

  useEffect(() => {
    setValue(selectedIndex);
  }, [selectedIndex]);

  const checkDisabledOrNot = (index: number) => {
    let disabled: boolean = false;
    if (disabledIndexes?.length > 0) {
      disabled = disabledIndexes.includes(index);
    } else {
      disabled = disabledIndex === index;
    }

    return disabled;
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: underline ? 1 : 0, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="leap360-tabs"
          orientation={vertical ? "vertical" : "horizontal"}
          centered={centered}
          scrollButtons={scrollButtons}
          variant={variant}
          allowScrollButtonsMobile={allowScrollButtonsMobile}
          visibleScrollbar={visibleScrollbar}
          selectionFollowsFocus={selectionFollowsFocus}
        >
          {TabsTiles.map((tabName: any, index: any) => (
            <Tab
              key={index}
              label={tabName}
              id={`leap360-tab-${index}`}
              aria-controls={`leap360-tabpanel-${index}`}
              disabled={checkDisabledOrNot(index)}
            />
          ))}
        </Tabs>
      </Box>
      {TabsContent.map((content: any, index: any) => (
        <TabPanel value={value} index={index} key={index}>
          {content}
        </TabPanel>
      ))}
    </Box>
  );
};
export interface ITabs {
  TabsTiles: any;
  TabsContent: any;
  panelClass?: string;
  vertical?: boolean;
  direction?: "left" | "right";
  variant?: "standard" | "fullWidth" | "scrollable";
  underline?: boolean;
  selectedIndex?: any;
  disabledIndex?: number;
  disabledIndexes?: any;
  setSelectedIndex?: any;
  centered?: boolean;
  allowScrollButtonsMobile?: boolean;
  scrollButtons?: "auto" | boolean;
  visibleScrollbar?: boolean;
  selectionFollowsFocus?: boolean;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}